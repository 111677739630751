export const createNewUrl = (
  windowLocation,
  routerLocation,
  id = "",
  firstLevel
) => {
  if (windowLocation?.pathname) {
    if (!id) return windowLocation.pathname;

    if (firstLevel) {
      return routerLocation.pathname;
    } else {
      return windowLocation.pathname.endsWith("/")
        ? `${windowLocation.pathname}${id}`
        : `${windowLocation.pathname}/${id}`;
    }
  }
  return "";
};
