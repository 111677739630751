import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'd3-mitch-tree/dist/css/d3-mitch-tree-theme-default.min.css'
import 'd3-mitch-tree/dist/css/d3-mitch-tree.min.css'
import 'react-simple-tree-menu/dist/main.css'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/common/header'
import Footer from './components/common/footer'
import Home from './views/home'
import TreePage from './views/tree'
import AboutUs from './views/about-us'
import FullTaxonomyTable from './views/fullTaxonomyTable'
import TermsAndConditions from './views/terms'
import PrivacyPolicy from './views/privacypolicy'
import './App.css'

function App() {
  const [consentIsGiven, setConsentIsGiven] = useState(false)
  return (
    <div className='App'>
      <BrowserRouter>
        <Header {...{ consentIsGiven, setConsentIsGiven }} />
        <Routes>
          <Route
            path='/'
            element={<Home {...{ consentIsGiven, setConsentIsGiven }} />}
          ></Route>
          <Route
            path='/tree'
            element={<TreePage {...{ consentIsGiven, setConsentIsGiven }} />}
          ></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route
            path='/terms-conditions'
            element={<TermsAndConditions />}
          ></Route>
          <Route path='/privacypolicy' element={<PrivacyPolicy />}></Route>
          <Route
            path='/fulltaxonomytable'
            element={
              <FullTaxonomyTable {...{ consentIsGiven, setConsentIsGiven }} />
            }
          >
            <Route
              path=':tableElement'
              element={
                <FullTaxonomyTable {...{ consentIsGiven, setConsentIsGiven }} />
              }
            />
          </Route>
          <Route path='/feedback' element={<Header />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
