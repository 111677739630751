import React, { useState, useEffect, useRef } from 'react'
import TaxonomyHeader from '../../components/page/taxonomy/header'
import TaxonomyCard from '../../components/page/taxonomy/card'
import BreadCrumb from '../../components/common/breadCrumb'
import data from '../../taxonomy.json'
import './style.css'
import HidenCardChildrens from '../../components/page/taxonomy/hidenCardChildrens'
import EdgeCases from '../../components/page/taxonomy/edgecases'
import Examples from '../../components/page/taxonomy/examples'
import Home from '../home'
import setPageCrumbs from '../setPageCrumbs'
import { createNewUrl } from './createNewUrl'
import { useLocation } from 'react-router'

const FullTaxonomy = ({ consentIsGiven, setConsentIsGiven }) => {
  const [pageData, setData] = useState([])
  const [parentId, setParent] = useState('root')
  const [firstLevel, setFirstLevel] = useState(true)
  const [pageItem, setItem] = useState({})
  const [selectOptions, setOptions] = useState([])
  const [breadCrumbItems, setCrumbs] = useState([])
  let pageRef = useRef(null)
  const routerLocation = useLocation()

  useEffect(() => {
    data.forEach((element) => {
      selectOptions.push({ value: element.id, label: element.name })
    })
    setOptions(selectOptions)
    let pageItems = data.filter((a) => a['parent-id'] === parentId)
    let targetItem = data.find((a) => a.id === parentId)
    setItem(targetItem)
    setData(pageItems)
    if (parentId !== 'root') {
      setFirstLevel(false)
    }
    if(pageRef?.current?.scrollIntoView) pageRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [parentId])

  const setPage = (id) => {
    if (id === 'root') setFirstLevel(true)
    setParent(id)
    const crumbs = setPageCrumbs(id, data).filter((x) => !x['hide-in-tree'])

    setCrumbs(crumbs)
    // used by plausible analytics
    if (window && window.location && window.location.pathname && id) {
      const newUrl = createNewUrl(
        window.location,
        routerLocation,
        id,
        id === 'root'
      )
      window.history.pushState('', '', newUrl)
    }
  }

  return consentIsGiven ? (
    <div className='page__container' ref={pageRef}>
      <TaxonomyHeader
        hasChildren={pageData.length > 0}
        firstLevel={firstLevel}
        node={pageItem}
        navigatePage={setPage}
      />
      <div className='bread__crumb__taxonomy'>
        <div className='container'>
          <div className='row'>
            <BreadCrumb
              breadCrumbItems={breadCrumbItems}
              navigatePage={setPage}
              page='fullTaxonomy'
            />
          </div>
        </div>
      </div>
      {pageData.length > 0 ? (
        <div className='card-container'>
          <div className='container'>
            <div className='row'>
              {pageData.map((item) => (
                <>
                  <TaxonomyCard
                    onpageChang={() => setPage(item.id)}
                    key={item.id}
                    node={item}
                  />
                  {item['hide-in-tree'] && (
                    <HidenCardChildrens
                      node={item}
                      onpageChang={() => setPage(item.id)}
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className='card-container'>
          <div className='container'>
            <div className='row'>
              {pageItem.exemplars && pageItem.exemplars.length ? (
                <Examples node={pageItem} />
              ) : null}
              {pageItem['edge-cases'] && pageItem['edge-cases'].length ? (
                <EdgeCases node={pageItem} />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Home
      consentIsGiven={consentIsGiven}
      setConsentIsGiven={setConsentIsGiven}
      showConsent={true}
      returnAddress='/fullTaxonomyTable'
    />
  )
}

export default FullTaxonomy
