import React from 'react'
import { ReactComponent as MegaPhone } from '../../../assets/images/megaphone.svg'
const FeedbackForm = ({ category }) => {
  const link = formatFeedbackFormLink(
    process.env.REACT_APP_FEEDBACK_URL,
    category
  )

  return (
    <a
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      className='nav-a px-2 a-dark'
    >
      <span className='feedback__btn'>
        <MegaPhone />
        Feedback <hr />
      </span>
    </a>
  )
}
export default FeedbackForm

export const formatFeedbackFormLink = (feedbackUrl, category) => {
  const categoryIsString = category && typeof category === 'string'

  if (categoryIsString) return `${feedbackUrl}?harmCategory=${category}`

  return feedbackUrl
}
